/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { IButtonProps } from './Button.types';
import ButtonStyles from './Button.styles';

const BUTTON: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { children, ...rest } = props;

  return (
    <ButtonStyles.MainButton {...rest}>{children}</ButtonStyles.MainButton>
  );
};

export default BUTTON;
