import * as Yup from 'yup';

export const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters.')
    .max(50, 'Name cannot exceed 50 characters.'),
  subject: Yup.string()
    .min(6, 'Subject must be at least 6 characters.')
    .max(50, 'Subject cannot exceed 50 characters.')
    .required('Required'),
  comments: Yup.string()
    .min(6, 'Comments must be at least 6 characters.')
    .max(1024, 'Comments cannot exceed 1024 characters.')
    .required('Required'),
  email: Yup.string()
    .email('You must provide a valid email address.')
    .required('Required'),
});
