import React from 'react';
import {
  Heading,
  VStack,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  SimpleGrid,
  GridItem,
  Textarea,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import SEO from '@/components/Seo';
import { IPageProps } from '@/common/types/page.types';
import { DefaultContactPageProps } from '@/common/props/page.props';
import { ContactFormSchema } from '@/common/form-schemas/contact-form.schema';
import FormConstants from '@/models/forms/Form.constants';
import BUTTON from '@/components/Button/Button.component';
import ContainerStyles from '@/components/container/Container.styles';
import { StaticQueryDocument, graphql } from 'gatsby';
import get from 'lodash/get';
import HERO from '@/components/hero/Hero.component';

function ContactIndex({ data, location }: IPageProps): React.ReactNode {
  const [page] = get(data, 'allContentfulPage.nodes');
  const { heroImage, title, subTitle, sections } = page;
  const { seoTitle, seoDescription } = DefaultContactPageProps;

  // @TODO BLOG POST
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname={location.pathname}
      />

      <HERO
        image={heroImage.gatsbyImage}
        title={title}
        subTitle={subTitle}
        showTitle
      />
      <ContainerStyles.PageContainer>
        <Flex
          padding={{ base: '12px', md: '72px' }}
          gridColumnGap={12}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box
            w={{ base: '100%', md: '66%' }}
            mb={{ base: '4.5rem', md: '0px' }}
          >
            {/* @todo: Create Form Component with below */}
            <Formik
              initialValues={FormConstants.ContactFormInitialValues}
              validationSchema={ContactFormSchema}
              onSubmit={(values) => {
                // eslint-disable-next-line no-console
                console.log(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                errors,
                touched,
                isSubmitting,
                isValid,
                values,
              }) => (
                <form
                  id="ContactForm"
                  name="ContactForm"
                  noValidate
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="ContactForm" />
                  <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
                    <GridItem colSpan={{ base: 2, md: 1 }}>
                      <Field name="name">
                        {({ field }) => (
                          <FormControl isInvalid={errors.name && touched.name}>
                            <FormLabel htmlFor="firstName">Name</FormLabel>
                            <Input
                              {...field}
                              id="name"
                              focusBorderColor="brand.blue"
                              placeholder="Jane Doe"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              ref={inputRef}
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={{ base: 2, md: 1 }}>
                      <Field name="email">
                        {({ field }) => (
                          <FormControl
                            isInvalid={errors.email && touched.email}
                            isRequired
                          >
                            <FormLabel htmlFor="email">Email Address</FormLabel>
                            <Input
                              {...field}
                              id="email"
                              focusBorderColor="brand.blue"
                              placeholder="Jane.Doe@example.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Field name="subject">
                        {({ field }) => (
                          <FormControl
                            isInvalid={errors.subject && touched.subject}
                            isRequired
                          >
                            <FormLabel htmlFor="subject">Subject</FormLabel>
                            <Input
                              {...field}
                              id="subject"
                              focusBorderColor="brand.blue"
                              placeholder="RE: My New Website Project"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.subject}
                            />
                            <FormErrorMessage>
                              {errors.subject}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Field name="comments">
                        {({ field }) => (
                          <FormControl
                            isInvalid={errors.comments && touched.comments}
                            isRequired
                          >
                            <FormLabel htmlFor="comments">
                              Questions/Comments
                            </FormLabel>
                            <Textarea
                              {...field}
                              id="comments"
                              focusBorderColor="brand.blue"
                              placeholder="I want to get started today, what else do you need from me?"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.comments}
                              size="lg"
                            />
                            <FormErrorMessage>
                              {errors.comments}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem colSpan={2} textAlign="right">
                      <BUTTON
                        mt={4}
                        isLoading={isSubmitting}
                        disabled={!isValid}
                        type="submit"
                      >
                        Submit
                      </BUTTON>
                    </GridItem>
                  </SimpleGrid>
                </form>
              )}
            </Formik>
          </Box>

          {/* <Spacer width="full" /> */}
          <Box textAlign="right" flex={1}>
            <Heading w="full">Be Free Studios</Heading>
            <Text alignSelf="flex-start">
              I&#39;m excited to hear from you. Let&#39;s create something
              together.{' '}
            </Text>

            <Box mt="16px">
              <Text fontWeight="bold">Burton Podczerwinski</Text>
              {/* <Text>
                <a href="tel:(630) 537-0006">(630) 537-0006</a>
              </Text> */}
              <Text>
                <a href="mailto:burton@befreestudios.dev?subject=Contact from web app">
                  info@BeFreeStudios.dev
                </a>
              </Text>
            </Box>

            {/* <Button
              onClick={(event: BFS_ClickEvent) => {
                sendDataToGTM({
                  event: 'test-event-with-params',
                  customData: 'This is some data',
                });
              }}
            >
              TEST GTM EVENT
            </Button> */}
          </Box>
        </Flex>
      </ContainerStyles.PageContainer>
    </>
  );
}

export default ContactIndex;

export const contactPageQuery: StaticQueryDocument = graphql`
  query Contact {
    allContentfulPage(
      filter: { contentful_id: { eq: "12T4ufYvccyOdnUDc8XGyD" } }
    ) {
      nodes {
        title
        subTitle
        slug
        url {
          id
          url
        }
        heroImage: heroImage {
          gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1680)
        }
      }
    }
  }
`;
