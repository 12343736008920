import { chakra, Button, Link } from '@chakra-ui/react';

const MainButton = chakra(Button, {
  label: 'bfs-button',
  baseStyle: {
    backgroundColor: 'brand.gray',
    borderRadius: 0,
    border: '2px solid #515152',
    color: 'brand.lightTan',
    padding: '22px',
    textTransform: 'uppercase',
    _disabled: {
      _hover: {
        backgroundColor: 'brand.gray',
        border: '2px solid #515152',
        color: 'brand.lightTan',
        transform: 'none',
        boxShadow: 'none',
      },
    },
    _hover: {
      backgroundColor: 'brand.blue',
      border: '2px solid #2b2b2b',
      color: 'brand.lightTan',
      transform: 'scale(1.12)',
      boxShadow: '0 10px 8px -6px #515152',
    },
  },
});

const LinkButton = chakra(Link, {
  label: 'link-button',
  baseStyle: {
    color: 'brand.blue',
    fontWeight: 'bold',
    _hover: {
      color: 'brand.orange',
      textDecoration: 'underline',
    },
    _activeLink: {
      color: 'brand.orange',
      _hover: { textDecoration: 'none', cursor: 'inherit' },
    },
  },
});

export default { MainButton, LinkButton };
